/* eslint-disable @next/next/no-img-element */
import type { NextPage } from "next";
import { useUser } from "@auth0/nextjs-auth0";
import Login from "components/Login";
import { Box, Center, chakra, Heading } from "@chakra-ui/react";
import Head from "next/head";
import { Bar } from "components/shared/layout/Bar";

const HomeView = () => (
  <Box w="100%" h="calc(100vh - 56px)" backgroundColor="flevo.jade">
    <Center w="100%" h={["50%", "70%"]} flexDirection="column">
      <Heading
        color="white"
        fontWeight="semibold"
        letterSpacing={2}
        mt={["6", "8"]}
        fontSize={["xl", "2xl", "3xl", "5xl"]}
      >
        <chakra.span
          textDecoration="underline"
          textDecorationColor="flevo.lime"
          textDecorationThickness="2px"
          borderColor="flevo.lime"
          textUnderlineOffset="2px"
        >
          Rompemos barreras
        </chakra.span>{" "}
        financieras <br></br>para fomentar la{" "}
        <chakra.span
          border="1px solid"
          borderColor="flevo.lime"
          borderRadius="full"
          px="2"
        >
          inclusión
        </chakra.span>
        <br></br> educativa en Latinoamérica
      </Heading>
    </Center>
    <Center>
      <Box maxW="80px" justifyContent="end">
        <img src="/flevo-logo-white.png" alt="flevo logo" />
      </Box>
    </Center>
  </Box>
);

const Home: NextPage = () => {
  const { user, error, isLoading } = useUser();
  if (isLoading) return <></>;
  if (error) return <div>{error.message}</div>;

  if (user) {
    return (
      <>
        <Head>
          <title>Flevo Dashboard | Home</title>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
        </Head>
        <Bar>
          <HomeView />
        </Bar>
      </>
    );
  }

  return (
    <>
      <Head>
        <title>Flevo Dashboard | Login</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Box bg="gray.50" w="full" h="full">
        <Login />
      </Box>
    </>
  );
};

export default Home;
